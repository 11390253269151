import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

const header = {
  backgroundColor: '#623cea',
  width: '100%',
  padding: '1.5em 0',
  maxHeight: '4.5em'
}

const navBar = {
  width: '100%',
  maxWidth: '1200px',
  margin: '0 auto',
  padding: '0 1.5em',
}
const ul = {
  display: 'flex',
  justifyContent: 'space-between',
}

const li = {
  display: 'inline-block',
  marginLeft: '1em',
  "&:firstChild": {
    position: 'relative',
    margin: 0,
    flexBasis: '100%',
  }
}
const anchor = {
  textDecoration: 'none',
  color: 'DarkGray',
  fontWeight: 600,
  transition: 'all 0.2s',
  borderBottom: '2px solid #623cea',
  "&:hover": {
    color: 'white',
  }
}
const rootAnchor = {
  textDecoration: 'none',
  color: 'white',
  fontWeight: 900,
  transition: 'all 0.2s',
  fontSize: '1.5em'
}

const activeLinkStyle = {
  color: 'white',
}

const Header = ({ siteTitle, menuLinks }) => (
  <div
    style={header}
  >
    <nav style={navBar}>
      <ul style={ul}>
        <li key={"root"} style={li}>
        <Link
          to="/"
          style={rootAnchor}
        >
          {siteTitle}
        </Link>
        </li>
      {
        menuLinks.map(link =>
          <li key={link.name} style={li}>
            <Link style={anchor} activeStyle={activeLinkStyle} to={link.link}>{link.name}</Link>
          </li>)
      }
      </ul>
    </nav>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header
