import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import DefaultLayout from "/tmp/24f537f9/src/components/layout.js";
import SEO from '../components/seo';
import { Link } from 'gatsby';
import ConnectorImage from '../components/connectorImage';
import PinTable from '../components/pins';
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>


      <SEO title="Getting Started" keywords={['pi2c', 'started']} />
      <br />
      <MDXTag name="h2" components={components}>{`Getting Started`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "#example"
          }}>{`Example`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "#wifi"
          }}>{`PI2c OS WiFi`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "#discovery"
          }}>{`Discovery`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "#wiring"
          }}>{`Pinout & Wiring`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "#pi2chat"
          }}>{`PI2c Hat`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "#savenamed"
          }}>{`Saving named instruction sets`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "#serial"
          }}>{`Serial TCP/Websockets`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "#security"
          }}>{`Security/Updates`}</MDXTag></MDXTag>
      </MDXTag>
      <a id="example" />
      <MDXTag name="h3" components={components}>{`Example`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{
          "href": "/rest-api",
          "title": "Rest API"
        }}>{`REST API details`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Nothing beats a quick example`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`async function run() {
  try {
    const MCP9808_I2CADDR_DEFAULT        = 0x18
    const MCP9808_REG_MANUF_ID             = 0x06
    const meta = new Map();
    const user = 'none';
    const password = 'none';
    const deviceHost = 'GO-1234567.local'; //or ip address if your OS does not support this
    meta.set('Authorization', 'Basic ' + Buffer.from(\`\${user}:\${password}\`).toString('base64'));
    meta.set('Content-Type', 'application/json');
    const headers = new Headers(meta);
    const instructions = [
      {
        func: 'i2cBegin',
      }, {
        func: 'i2cSetSlaveAddress',
        arg1: MCP9808_I2CADDR_DEFAULT
      }, {
        func: 'i2cSetBaudRate',
        arg1: 100000
      }, {
        func: 'i2cWrite',
        arg1: Buffer.from([MCP9808_REG_MANUF_ID]).toString('base64')
      }, {
        func: 'i2cRead',
        arg1: 2
      }
    ];
    //Setup device for i2c and get Manufacturer id of device
    let resp = await fetch(\`http://\${deviceHost}/api/v1/instr/exec\`,
      { method: 'POST', body: JSON.stringify(instructions), headers });
    if(!resp.ok) {
      const respText = await resp.text();
      console.log(\`Error processing instructions. Response \${respText}\`);
      process.exit(2);
    }
    let jsonResp = await resp.json();
    if(jsonResp.success == false) {
      console.debug(\`Error communicating with response\\n\${JSON.stringify(jsonResp, undefined, 2)}\`);
    } else {
      console.debug(\`Success communicating with response\\n\${JSON.stringify(jsonResp, undefined, 2)}\`);
      console.log(\`Manufacturer id is \${Buffer.from(jsonResp.instructions[4].returnValue, 'base64').toString('ascii')}\`)
    }
    //Clean up and end i2c comm
    resp = await fetch(\`http://\${deviceHost}/api/v1/instr/exec\`, { method: 'POST', body:
      JSON.stringify([{
        func: 'i2cEnd'
      }]),
      headers
    });
    if(!resp.ok) {
      console.log(\`Error processing instructions\`);
      process.exit(3);
    }
  } catch(error) {
    console.log(\`Unknown error. Message - \${error.message}\`);
  }
}
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`In this javascript example we are communicating with a MCP9808 I2c temperature sensor.
The `}<Link to="/rest-api#schema-instructions">{`Instructions`}</Link>{`
and `}<Link to="/rest-api#schema-instructions-response">{`InstructionsResponse`}</Link>{`
schemes in the API has greater detail
on the structure and properties. You can chain
instructions and POST them to `}<Link to="/rest-api#op-post-api-v1-instr-exec">{`/api/v1/instr/exec`}</Link>{`. Instructions which
return data can find the value in the `}<Link to="/rest-api#schema-instructions-response">{`InstructionsResponse`}</Link>{`.
Specifically the property `}<MDXTag name="strong" components={components} parentName="p">{`returnValue`}</MDXTag>{` at the corresponding instruction index of the response.
Binary data is not valid in REST so it needs to be converted to and from base64 strings.`}</MDXTag>
      <br />
      <a id="wifi" />
      <MDXTag name="h3" components={components}>{`Configuring WiFi for PI2c OS`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li">{`Get names of all WiFi networks configured and active`}</MDXTag>
          <MDXTag name="p" components={components} parentName="li">{`GET `}<MDXTag name="a" components={components} parentName="p" props={{
              "href": "http://REST_SERVER:63333/wifi/info"
            }}>{`http://REST_SERVER:63333/wifi/info`}</MDXTag></MDXTag>
        </MDXTag>
      </MDXTag>
      <MDXTag name="h4" components={components}>{`▶ 200 - Response is an object with two keys 'all' and 'active'.`}</MDXTag>
      <MDXTag name="h6" components={components}>{`application/json`}</MDXTag>
  <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>all <strong>(required)</strong></td>
        <td>
          array(string)
        </td>
        <td>all configured WiFi network names (max 1)</td>
      </tr>
      <tr>
        <td>active <strong>(required)</strong></td>
        <td>
          array(string)
        </td>
        <td>network names which are active</td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li">{`Add WiFi connection credentials. This will delete existing WiFi connections and corresponding credentials. This is useful to give the device short term internet access for updates and/or control the device while on the same wireless network.`}</MDXTag>
          <MDXTag name="p" components={components} parentName="li">{`POST `}<MDXTag name="a" components={components} parentName="p" props={{
              "href": "http://REST_SERVER:63333/wifi/add"
            }}>{`http://REST_SERVER:63333/wifi/add`}</MDXTag></MDXTag>
        </MDXTag>
      </MDXTag>
      <MDXTag name="h4" components={components}>{`Request body`}</MDXTag>
      <MDXTag name="h6" components={components}>{`application/json`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>ssid <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Wifi router name</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>passphrase <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Password of Wifi router</td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li">{`Remove all wifi credentials`}</MDXTag>
          <MDXTag name="p" components={components} parentName="li">{`POST `}<MDXTag name="a" components={components} parentName="p" props={{
              "href": "http://REST_SERVER:63333/wifi/remove"
            }}>{`http://REST_SERVER:63333/wifi/remove`}</MDXTag></MDXTag>
        </MDXTag>
      </MDXTag>
      <MDXTag name="h4" components={components}>{`Examples`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-bash"
        }}>{`  # Command line example adding WiFi credentials
  curl --header "Content-Type: application/json" --request POST
  --data '{"ssid":"userSSID","passphrase": "myPassphrase"}' http://\${REST_SERVER}:63333/wifi/add

  # Command line example remove all WiFi credentials
  curl --header "Content-Type: application/json" --request POST http://\${REST_SERVER}:63333/wifi/remove
`}</MDXTag></MDXTag>
      <br />
      <a id="discovery" />
      <MDXTag name="h3" components={components}>{`PI2c OS Device Discovery`}</MDXTag>
      <MDXTag name="p" components={components}>{`Each PI2c can be reached via a Bonjour/Zeroconf address. For example GO-1234567.local, where 1234567 is the PI2c ID.
On some operating systems the .local is not required. If this does not work for Windows you can `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://support.apple.com/kb/DL999?viewlocale=en_US&locale=en_US"
        }}>{`Download and install Bonjour Print Services for Windows v2.0.2`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`On a MAC run the following command and look for devices prefixed with GO-`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-bash"
        }}>{`dns-sd -B _ssh 
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`On a linux with avahi-browse installed run the following command and look for devices prefixed with GO-`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-bash"
        }}>{`avahi-browse -rac
`}</MDXTag></MDXTag>
      <br />
      <a id="wiring" />
      <MDXTag name="h3" components={components}>{`Pinout & Wiring`}</MDXTag>
      <br />
      <MDXTag name="p" components={components}>{`PI2c software uses the pin numbers corresponding with the PI2c hat. If you are not
using the hat and are connecting straight to the Pi you can use the
following chart to help with the mapping. `}<MDXTag name="strong" components={components} parentName="p">{`These are the physical pin numbers`}</MDXTag>{` .
The software allows the i2c, spi, and pwm pins to also act as gpio. `}</MDXTag>

      <br />
      <PinTable data={[[1, 5], [3, 3], [5, 21], [7, 23], [8, 19], [9, 24], [12, 8], [13, 29], [14, 10], [15, 31], [16, 12], [17, 35], [18, 33]]} />
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{}}>{`const map = [[1, 5], [3, 3], [5, 21], [7, 23], [8, 19], [9, 24], [12, 8], 
[13, 29], [14, 10], [15, 31], [16, 12], [17, 35], [18, 33]]
`}</MDXTag></MDXTag>
      <MDXTag name="table" components={components}>
        <MDXTag name="thead" components={components} parentName="table">
          <MDXTag name="tr" components={components} parentName="thead">
            <MDXTag name="th" components={components} parentName="tr" props={{
              "align": null
            }}>{`Function`}</MDXTag>
            <MDXTag name="th" components={components} parentName="tr" props={{
              "align": null
            }}>{`PI2c Hat`}</MDXTag>
            <MDXTag name="th" components={components} parentName="tr" props={{
              "align": null
            }}>{`Pi Header`}</MDXTag>
          </MDXTag>
        </MDXTag>
        <MDXTag name="tbody" components={components} parentName="table">
          <MDXTag name="tr" components={components} parentName="tbody">
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`I2c`}</MDXTag>
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}></MDXTag>
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}></MDXTag>
          </MDXTag>
          <MDXTag name="tr" components={components} parentName="tbody">
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`SDA`}</MDXTag>
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`3`}</MDXTag>
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`3`}</MDXTag>
          </MDXTag>
          <MDXTag name="tr" components={components} parentName="tbody">
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`SCL`}</MDXTag>
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`1`}</MDXTag>
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`5`}</MDXTag>
          </MDXTag>
          <MDXTag name="tr" components={components} parentName="tbody">
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`UART`}</MDXTag>
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}></MDXTag>
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}></MDXTag>
          </MDXTag>
          <MDXTag name="tr" components={components} parentName="tbody">
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`UART_TX`}</MDXTag>
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`12`}</MDXTag>
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`8`}</MDXTag>
          </MDXTag>
          <MDXTag name="tr" components={components} parentName="tbody">
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`UART_RX`}</MDXTag>
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`14`}</MDXTag>
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`10`}</MDXTag>
          </MDXTag>
          <MDXTag name="tr" components={components} parentName="tbody">
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`SPI`}</MDXTag>
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}></MDXTag>
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}></MDXTag>
          </MDXTag>
          <MDXTag name="tr" components={components} parentName="tbody">
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`SPI_CE0`}</MDXTag>
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`9`}</MDXTag>
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`24`}</MDXTag>
          </MDXTag>
          <MDXTag name="tr" components={components} parentName="tbody">
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`SPI_MOSI`}</MDXTag>
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`8`}</MDXTag>
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`19`}</MDXTag>
          </MDXTag>
          <MDXTag name="tr" components={components} parentName="tbody">
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`SPI_MISO`}</MDXTag>
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`5`}</MDXTag>
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`21`}</MDXTag>
          </MDXTag>
          <MDXTag name="tr" components={components} parentName="tbody">
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`SPI_CLK`}</MDXTag>
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`7`}</MDXTag>
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`23`}</MDXTag>
          </MDXTag>
          <MDXTag name="tr" components={components} parentName="tbody">
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`PWM`}</MDXTag>
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}></MDXTag>
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}></MDXTag>
          </MDXTag>
          <MDXTag name="tr" components={components} parentName="tbody">
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`PWM0`}</MDXTag>
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`16`}</MDXTag>
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`12`}</MDXTag>
          </MDXTag>
          <MDXTag name="tr" components={components} parentName="tbody">
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`PWM1`}</MDXTag>
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`18`}</MDXTag>
            <MDXTag name="td" components={components} parentName="tr" props={{
              "align": null
            }}>{`33`}</MDXTag>
          </MDXTag>
        </MDXTag>
      </MDXTag>
      <br />
      <a id="primary-pi2chat" />
      <MDXTag name="h3" components={components}>{`PI2c Hat`}</MDXTag>
      <MDXTag name="h4" components={components}>{`Connectors`}</MDXTag>
      <div style={{
        maxWidth: '400px',
        marginBottom: '1.45rem'
      }}>
  <ConnectorImage />
      </div>
      <MDXTag name="h4" components={components}><MDXTag name="strong" components={components} parentName="h4">{`SW 3V3/5V`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`The PI2c hat has a switch to output either no output, 3.3v, or 5v to pins 4 and 6.
The amount of current is limited by the power supply of the device.`}</MDXTag>
      <MDXTag name="h4" components={components}><MDXTag name="strong" components={components} parentName="h4">{`Red & Green Led`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`The PI2c hat has two leds you can control. One red and one green.
The red led will blink once the device
is fully running on startup. It can be used as needed afterwards.`}</MDXTag>
      <MDXTag name="p" components={components}>{`API Instruction `}<Link to="/rest-api#schema-led">{`led`}</Link></MDXTag>
      <br />
      <a id="savenamed" />
      <MDXTag name="h3" components={components}>{`Saving named instruction sets`}</MDXTag>
      <MDXTag name="p" components={components}>{`To save from having to send many instructions frequently you can store them as a named set
on the device and execute later.`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`List stored instruction set names`}<MDXTag name="ul" components={components} parentName="li">
            <MDXTag name="li" components={components} parentName="ul"><Link to="/rest-api#op-get-api-v1-instr-stored">GET /api/v1/instr/stored</Link></MDXTag>
          </MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Get stored instructions of set with name`}<MDXTag name="ul" components={components} parentName="li">
            <MDXTag name="li" components={components} parentName="ul"><Link to="/rest-api#op-get-api-v1-instr-stored-instructionsetname">GET /api/v1/instr/stored/[InstructionSetName]</Link></MDXTag>
          </MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Add stored instruction set with name`}<MDXTag name="ul" components={components} parentName="li">
            <MDXTag name="li" components={components} parentName="ul"><Link to="/rest-api#op-post-api-v1-instr-stored-instructionsetname">POST /api/v1/instr/stored/[InstructionSetName]</Link></MDXTag>
          </MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Execute stored instruction set with name`}<MDXTag name="ul" components={components} parentName="li">
            <MDXTag name="li" components={components} parentName="ul"><Link to="/rest-api#op-post-api-v1-instr-stored-instructionsetname-exec">POST /api/v1/instr/stored/[InstructionSetName]/exec</Link></MDXTag>
          </MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Get stored instructions set with name last execution log`}<MDXTag name="ul" components={components} parentName="li">
            <MDXTag name="li" components={components} parentName="ul"><Link to="/rest-api#op-get-api-v1-instr-stored-instructionsetname-log">GET /api/v1/instr/stored/[InstructionSetName]/log</Link></MDXTag>
          </MDXTag></MDXTag>
      </MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-bash"
        }}>{`  #Command line example get all stored instruction set names
  curl --header "Content-Type: application/json" --request GET http://\${REST_SERVER}/api/v1/instr/stored

  #Command line example post new stored instruction set with special name initialize
  #initialize will be run everytime PI2c starts up
  curl --header "Content-Type: application/json"   --request POST
  --data '[{"func":"mode","arg1": 11, "arg2":"OUTPUT"}]' http://\${REST_SERVER}/api/v1/instr/stored/initialize
`}</MDXTag></MDXTag>
      <br />
      <a id="serial" />
      <MDXTag name="h4" components={components}>{`Serial TCP/Websockets`}</MDXTag>
      <MDXTag name="p" components={components}>{`You can use the PI2c as a Serial to network device. Note - CTS and RTS are not supported`}</MDXTag>
      <MDXTag name="ol" components={components}>
        <MDXTag name="li" components={components} parentName="ol">{`Wire your device to the PI2c using the info above`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`Configure the Serial baudrate with via the Rest api`}</MDXTag>
      </MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-bash"
        }}>{`# Command line example set serial baudrate
curl --request POST http://\${REST_SERVER}:82/api/v1/setBaudrate/115200
`}</MDXTag></MDXTag>
      <MDXTag name="ol" components={components} props={{
        "start": 3
      }}>
        <MDXTag name="li" components={components} parentName="ol">{`Using your desired platform connect to Websocket port 1337 for or TCP port 47070. Traffic over serial will be routed
to these sockets.`}</MDXTag>
      </MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-python"
        }}>{`# Python TCP write serial example
import socket
class SerialSocket:
  def __init__(self, sock=None):
    if sock is None:
      self.sock = socket.socket(socket.AF_INET, socket.SOCK_STREAM)
    else:
      self.sock = sock

  def connect(self, host, port):
    self.sock.connect((host, port))

  def write(self, msg):
    totalsent = 0
    MSGLEN = len(msg)
    while totalsent < MSGLEN:
      sent = self.sock.send(msg[totalsent:])
      if sent == 0:
        raise RuntimeError("socket connection broken")
      totalsent = totalsent + sent

  def read(self, num):
    chunks = []
    bytes_recd = 0
    MSGLEN = num
    while bytes_recd < MSGLEN:
      chunk = self.sock.recv(min(MSGLEN - bytes_recd, 2048))
      if chunk == b'':
        raise RuntimeError("socket connection broken")
      chunks.append(chunk)
      bytes_recd = bytes_recd + len(chunk)
    return b''.join(chunks)

serial = SerialSocket()
serial.connect('GO-XXXXXXX', 47070)
serial.write(bytearray("hello\\n", "utf-8"))
`}</MDXTag></MDXTag>
      <br />
      <a id="security" />
      <MDXTag name="h3" components={components}>{`Security/Updates`}</MDXTag>
      <MDXTag name="p" components={components}>{`PI2c will generally be plugged directly in to your computer so there is no chance
of someone getting remote access. If you give it WiFi access to your network it will still be
protected from the outside, unless you forwarded the ports, which is not recommended. You
can give the device a password to limit who can control it on your network. See`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`Get general settings including name, password, webURL`}<MDXTag name="ul" components={components} parentName="li">
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "/rest-api#op-get-api-v1-general"
              }}>{`GET /api/v1/general`}</MDXTag></MDXTag>
          </MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Post new settings`}<MDXTag name="ul" components={components} parentName="li">
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "/rest-api#schema-pi2c-general-settings"
              }}>{`POST /api/v1/general`}</MDXTag></MDXTag>
          </MDXTag></MDXTag>
      </MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-bash"
        }}>{`  # Command line example sending new settings
  curl --header "Content-Type: application/json" --request POST
  --data '{
   "name": "Some name",
   "webURL": "www.your-domain.com/locationOfYourCustomWebApplication",
   "password": "Some password"
  }' http://\${REST_SERVER}/api/v1/settings/general
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`The password can be ignored if you are directly plugged in.
It is recommended to occasionally put the device on your WiFi network so that it can auto update.`}</MDXTag>
      <MDXTag name="p" components={components}>{`If there is an update it should take no longer than three minutes to complete the automatic process,
and should only occur when the board is powered.
Afterwards, you can remove the device from your network if you prefer. You can confirm
completion by making a GET request to `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "/rest-api#op-get-api-v1-version"
        }}>{`/api/v1/version`}</MDXTag>{`
and confirming the version has changed.`}</MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      