// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/tmp/24f537f9/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-get-started-mdx": () => import("/tmp/24f537f9/src/pages/get-started.mdx" /* webpackChunkName: "component---src-pages-get-started-mdx" */),
  "component---src-pages-index-mdx": () => import("/tmp/24f537f9/src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-rest-api-mdx": () => import("/tmp/24f537f9/src/pages/rest-api.mdx" /* webpackChunkName: "component---src-pages-rest-api-mdx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/tmp/24f537f9/.cache/data.json")

