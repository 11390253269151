import { Link } from 'gatsby';
import Layout from "../../../src/components/layout";
import SEO from "../../../src/components/seo";
import React from 'react';
import { MDXTag } from '@mdx-js/tag';
export default {
  Link,
  Layout,
  SEO,
  React,
  MDXTag
};