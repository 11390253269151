import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import DefaultLayout from "/tmp/24f537f9/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../components/layout';
import LogoImage from '../components/logoImage';
import SEO from '../components/seo';
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>



      <SEO title="Setup" keywords={['pi2c', 'setup']} />
  <figure style={{
        float: 'right',
        fontSize: 'smaller',
        textAlign: 'center',
        margin: '0.5em',
        padding: '0.5em',
        maxWidth: '140px'
      }}>
    <a href="https://pi2c.concerco.cloud">
      <LogoImage />
      <figcaption>Website</figcaption>
  </a>
  </figure>
  <br />
      <MDXTag name="p" components={components}>{`  PI2c OS allows you to control GPIO, SPI, I2C, PWM, and serial via a REST API and communicate serial over TCP and Websockets.
You can flash the OS onto an SD card to begin or install and run the applications directly via npm. `}</MDXTag>
      <MDXTag name="p" components={components}>{`  Once you have successfully programmed the device and have your Pi running you can
view `}<Link to="/get-started">{`get started`}</Link>{` and `}<Link to="/rest-api">{`rest api`}</Link>{` help. `}</MDXTag>
      <br />
      <MDXTag name="h2" components={components}>{`Application Installation`}</MDXTag>
      <MDXTag name="p" components={components}>{`The applications repo's can be found on Github at `}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://github.com/gidjituser/pi2c-rest-api"
          }}>{`PI2c - Rest API`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://github.com/gidjituser/pi2c-serial"
          }}>{`PI2c - Serial`}</MDXTag></MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`Both repositories contain instructions to build, install, and run on your target.`}</MDXTag>
  <br />
      <MDXTag name="h2" components={components}>{`OS Installation`}</MDXTag>
      <MDXTag name="p" components={components}>{`The PI2c image can be downloaded and flashed onto an SD card. `}</MDXTag>
      <MDXTag name="h3" components={components}>{`Items needed`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`Raspberry Pi 3 A/B+ (At some point in
the future there will also be an image availabe for the Pi Zero W)`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`SD Card 8GB +`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`PI2c Zipped Image to flash on the SD `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://images.pi2c.concerco.cloud/pi2c-pi3.img.zip"
          }}>{`Download`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Application to flash the bridge image (Balena Etcher) onto the SD. `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://www.balena.io/etcher/"
          }}>{`Balena Etcher`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Optional USB drive`}</MDXTag>
      </MDXTag>
      <MDXTag name="h3" components={components}>{`Steps`}</MDXTag>
      <MDXTag name="ol" components={components}>
        <MDXTag name="li" components={components} parentName="ol">{`Leave Pi completely unpowered`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`Download and unzip the Bridge image`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`Use balenaEtcher to program the image onto your SD`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`Plug the SD into the Pi`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`Optionally insert a USB drive into the Pi`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`Connect the Pi via ethernet to your router or computer`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`Power the Pi with a stable source `}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`Wait at least 5 minutes for Pi to install latest firmware `}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`To find your device scan for it via Bonjour/Zeroconf. `}</MDXTag>
      <MDXTag name="p" components={components}>{`On a MAC run the following command and look for devices prefixed with GO-XXXXXXX`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-bash"
        }}>{`dns-sd -B _ssh 
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`On a linux with avahi-browse installed run the following command and look for devices prefixed with GO-XXXXXXX`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-bash"
        }}>{`avahi-browse -rac
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Or if you had inserted a USB drive (Device will log ip, host, and other info on powerup to a USB)`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`Remove USB from your Pi and place in your computer`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Open the file info.txt`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Get the hostname from the file GO-XXXXXXX or the ip at eth`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`Now let's test by getting the version`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-bash"
        }}>{`curl GO-XXXXXXX/api/v1/version
or
curl ip-address/api/v1/version
`}</MDXTag></MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      