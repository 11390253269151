import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}><MDXTag name="p" components={components}><a href="#schema-led">{`led`}</a>{` |`}{` `}{`
`}<a href="#schema-stored">{`stored`}</a>{` |`}{` `}{`
`}<a href="#schema-open">{`open`}</a>{` |`}{` `}{`
`}<a href="#schema-mode">{`mode`}</a>{` |`}{` `}{`
`}<a href="#schema-read">{`read`}</a>{` |`}{` `}{`
`}<a href="#schema-readbuf">{`readBuf`}</a>{` |`}{` `}{`
`}<a href="#schema-write">{`write`}</a>{` |`}{` `}{`
`}<a href="#schema-writebuf">{`writeBuf`}</a>{` |`}{` `}{`
`}<a href="#schema-pud">{`pud`}</a>{` |`}{` `}{`
`}<a href="#schema-pwmsetclockdivider">{`pwmSetClockDivider`}</a>{` |`}{` `}{`
`}<a href="#schema-pwmsetrange">{`pwmSetRange`}</a>{` |`}{` `}{`
`}<a href="#schema-pwmsetdata">{`pwmSetData`}</a>{` |`}{` `}{`
`}<a href="#schema-i2cbegin">{`i2cBegin`}</a>{` |`}{` `}{`
`}<a href="#schema-i2csetslaveaddress">{`i2cSetSlaveAddress`}</a>{` |`}{` `}{`
`}<a href="#schema-i2csetclockdivider">{`i2cSetClockDivider`}</a>{` |`}{` `}{`
`}<a href="#schema-i2csetbaudrate">{`i2cSetBaudRate`}</a>{` |`}{` `}{`
`}<a href="#schema-i2cread">{`i2cRead`}</a>{` |`}{` `}{`
`}<a href="#schema-i2cwrite">{`i2cWrite`}</a>{` |`}{` `}{`
`}<a href="#schema-i2cupdatereg">{`i2cUpdateReg`}</a>{` |`}{` `}{`
`}<a href="#schema-i2cend">{`i2cEnd`}</a>{` |`}{` `}{`
`}<a href="#schema-spibegin">{`spiBegin`}</a>{` |`}{` `}{`
`}<a href="#schema-spisetcspolarity">{`spiSetCSPolarity`}</a>{` |`}{` `}{`
`}<a href="#schema-spisetclockdivider">{`spiSetClockDivider`}</a>{` |`}{` `}{`
`}<a href="#schema-spisetdatamode">{`spiSetDataMode`}</a>{` |`}{` `}{`
`}<a href="#schema-spitransfer">{`spiTransfer`}</a>{` |`}{` `}{`
`}<a href="#schema-spiwrite">{`spiWrite`}</a>{` |`}{` `}{`
`}<a href="#schema-spiend">{`spiEnd`}</a>{` |`}{` `}{`
`}<a href="#schema-sleep">{`sleep`}</a>{` |`}{` `}{`
`}<a href="#schema-msleep">{`msleep`}</a>{` |`}{` `}{`
`}<a href="#schema-usleep">{`usleep`}</a></MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      