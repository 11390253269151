import React from 'react'

export default class PinTable extends React.Component {

  createTable = (data) => {
    let table = []
    
    // Outer loop to create parent
    let rowOne = []
    let rowTwo = []
    rowOne.push(<td style={{ borderBottom: 'none'}} key={`hat`}>{`PI2C Hat `}</td>)
    rowTwo.push(<td key={`header`}>{`Pi Header`}</td>)
    for(let i = 0; i < data.length - 1; i++) {
      let curr = data[i];
      //Inner loop to create children
      rowOne.push(<td style={{borderRight: '1px solid #80808022', borderBottom: 'none'}} key={`hat${i}`}>{`${curr[0]}`}</td>)
      rowTwo.push(<td style={{borderRight: '1px solid #80808022'}} key={`header${i}`}>{`${curr[1]}`}</td>)
      //Create the parent and add the children
    }
    let curr = data[data.length - 1];
    rowOne.push(<td style={{ borderBottom: 'none'}}  key={`hat${data.length - 1}`}>{`${curr[0]}`}</td>)
    rowTwo.push(<td key={`header${data.length - 1}`}>{`${curr[1]}`}</td>)
    
    table.push(<tr key='row1'>{rowOne}</tr>)
    table.push(<tr key='row2'>{rowTwo}</tr>)
    return table
  }
  
  render() {
    return(
      <table>
        <tbody>
          {this.createTable(this.props.data)}
        </tbody>
      </table>
    )
  }
}