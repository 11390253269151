import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import DefaultLayout from "/tmp/24f537f9/src/components/layout.js";
import SEO from '../components/seo';
import FuncLinks from 'supporting/funcLinks';
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>


      <SEO title="Rest API" keywords={['pi2c', 'rest', 'api']} />

      <MDXTag name="h2" components={components}>{`Table of Contents`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "#servers"
          }}>{`Servers`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "#paths"
          }}>{`Paths`}</MDXTag><MDXTag name="ul" components={components} parentName="li">
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#op-get-api-v1-version"
              }}>{`GET /api/v1/version`}</MDXTag>{` `}</MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#op-get-api-v1-general"
              }}>{`GET /api/v1/general`}</MDXTag>{` `}</MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#op-post-api-v1-general"
              }}>{`POST /api/v1/general`}</MDXTag>{` `}</MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#op-post-api-v1-instr-exec"
              }}>{`POST /api/v1/instr/exec`}</MDXTag>{` `}</MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#op-get-api-v1-instr-stored"
              }}>{`GET /api/v1/instr/stored`}</MDXTag>{` `}</MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#op-get-api-v1-instr-stored-instructionsetname"
              }}>{`GET /api/v1/instr/stored/{InstructionSetName}`}</MDXTag>{` `}</MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#op-post-api-v1-instr-stored-instructionsetname"
              }}>{`POST /api/v1/instr/stored/{InstructionSetName}`}</MDXTag>{` `}</MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#op-post-api-v1-instr-stored-instructionsetname-exec"
              }}>{`POST /api/v1/instr/stored/{InstructionSetName}/exec`}</MDXTag>{` `}</MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#op-get-api-v1-instr-stored-instructionsetname-log"
              }}>{`GET /api/v1/instr/stored/{InstructionSetName}/log`}</MDXTag>{` `}</MDXTag>
          </MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "#schemas"
          }}>{`Schemas`}</MDXTag><MDXTag name="ul" components={components} parentName="li">
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-pi2c-general-settings"
              }}>{`PI2c General Settings`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-pin-reset-type"
              }}>{`Pin reset type`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-poll-pin-direction"
              }}>{`Poll Pin direction`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-pin"
              }}>{`Pin`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-pin-mode"
              }}>{`Pin Mode`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-pullup"
              }}>{`Pullup`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-pin-value"
              }}>{`Pin Value`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-pin-numerical-value"
              }}>{`Pin Numerical Value`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-led"
              }}>{`led`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-open"
              }}>{`open`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-stored"
              }}>{`stored`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-mode"
              }}>{`mode`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-read"
              }}>{`read`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-readbuf"
              }}>{`readBuf`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-write"
              }}>{`write`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-writebuf"
              }}>{`writeBuf`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-pud"
              }}>{`pud`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-pwmsetclockdivider"
              }}>{`pwmSetClockDivider`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-pwmsetrange"
              }}>{`pwmSetRange`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-pwmsetdata"
              }}>{`pwmSetData`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-i2cbegin"
              }}>{`i2cBegin`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-i2csetslaveaddress"
              }}>{`i2cSetSlaveAddress`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-i2csetbaudrate"
              }}>{`i2cSetBaudRate`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-i2cread"
              }}>{`i2cRead`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-i2cwrite"
              }}>{`i2cWrite`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-i2cupdatereg"
              }}>{`i2cUpdateReg`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-i2cend"
              }}>{`i2cEnd`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-spibegin"
              }}>{`spiBegin`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-spisetcspolarity"
              }}>{`spiSetCSPolarity`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-spisetclockdivider"
              }}>{`spiSetClockDivider`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-spisetdatamode"
              }}>{`spiSetDataMode`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-spitransfer"
              }}>{`spiTransfer`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-spiwrite"
              }}>{`spiWrite`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-spiend"
              }}>{`spiEnd`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-sleep"
              }}>{`sleep`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-msleep"
              }}>{`msleep`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-usleep"
              }}>{`usleep`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-instructions"
              }}>{`Instructions`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-instruction-response"
              }}>{`Instruction Response`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-instructions-response"
              }}>{`Instructions Response`}</MDXTag></MDXTag>
            <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
                "href": "#schema-error"
              }}>{`Error`}</MDXTag></MDXTag>
          </MDXTag></MDXTag>
      </MDXTag>
      <a id="servers" />
      <MDXTag name="h2" components={components}>{`Servers`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>URL</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><a href="http://GO-XXXXXXX/api/v1" target="_blank">http://GO-XXXXXXX/api/v1</a></td>
      <td></td>
    </tr>
  </tbody>
      </table>
      <MDXTag name="h2" components={components}>{`Paths`}</MDXTag>
      <MDXTag name="h3" components={components}>{`GET /api/v1/version`}</MDXTag>
      <a id="op-get-api-v1-version" />
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`Get the device firmware version`}</MDXTag>
      </MDXTag>
      <MDXTag name="h4" components={components}>{`Responses`}</MDXTag>
      <MDXTag name="h5" components={components}>{`▶ 200 - Object with version property with firmware string value`}</MDXTag>
      <MDXTag name="h6" components={components}>{`application/json`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>version <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td></td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h3" components={components}>{`GET /api/v1/general`}</MDXTag>
      <a id="op-get-api-v1-general" />
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`Get general settings of the device`}</MDXTag>
      </MDXTag>
      <MDXTag name="h4" components={components}>{`Responses`}</MDXTag>
      <MDXTag name="h5" components={components}>{`▶ 200 - Response is an object with keys 'name', 'password', 'webURL'.`}</MDXTag>
      <MDXTag name="h6" components={components}>{`application/json`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>name</td>
        <td>
          string
        </td>
        <td>a user defined name for the device</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>password</td>
        <td>
          string
        </td>
        <td>a REST API password for Basic access authentication where the username is ignored</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>webURL</td>
        <td>
          string
        </td>
        <td>(advanced) a user defined URL serving custom HTML/JS to control the PI2c</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h3" components={components}>{`POST /api/v1/general`}</MDXTag>
      <a id="op-post-api-v1-general" />
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`Update the General settings of the PI2c device. Existing values are used for properties not sent.`}</MDXTag>
      </MDXTag>
      <MDXTag name="h4" components={components}>{`Request body`}</MDXTag>
      <MDXTag name="h6" components={components}>{`application/json`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>name</td>
        <td>
          string
        </td>
        <td>a user defined name for the device</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>password</td>
        <td>
          string
        </td>
        <td>a REST API password for Basic access authentication where the username is ignored</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>webURL</td>
        <td>
          string
        </td>
        <td>(advanced) a user defined URL serving custom HTML/JS to control the PI2c</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "name": "string",
  "password": "string",
  "webURL": "string"
}
`}</MDXTag></MDXTag>
      <MDXTag name="h4" components={components}>{`Responses`}</MDXTag>
      <MDXTag name="h5" components={components}>{`▶ 200 - Object with success of storing the instructions set`}</MDXTag>
      <MDXTag name="h6" components={components}>{`application/json`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>success <strong>(required)</strong></td>
        <td>
          boolean
        </td>
        <td></td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>error</td>
        <td>
          -- 
        </td>
        <td>Common Error object among all requests</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>error.code <strong>(required)</strong></td>
        <td>
          integer
        </td>
        <td>Number to identify the error for tracking purposes</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>error.message <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Information describing what caused the error</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`▶ default - Invalid packet, credentials, or some unexpected error`}</MDXTag>
      <MDXTag name="h6" components={components}>{`application/json`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>code <strong>(required)</strong></td>
        <td>
          integer
        </td>
        <td>Number to identify the error for tracking purposes</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>message <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Information describing what caused the error</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h3" components={components}>{`POST /api/v1/instr/exec`}</MDXTag>
      <a id="op-post-api-v1-instr-exec" />
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`Run instructions. All of the instructions can be found in schemas below.`}</MDXTag>
      </MDXTag>
      <MDXTag name="h4" components={components}>{`Request body`}</MDXTag>
      <MDXTag name="h6" components={components}>{`application/json`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>body</td>
        <td>
          array
        </td>
        <td><p>Array of instructions.
      Each instruction is an object with a key, 'func'
      whose value is the name of the function. It also may contain keys
      'arg1', 'arg2', 'arg3' and associated values corresponding to arguments for the function.</p>
      <FuncLinks />
      </td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`[
  {
    "func": "open",
    "arg1": 1,
    "arg2": "INPUT",
    "arg3": "PULL_UP"
  }
]
`}</MDXTag></MDXTag>
      <MDXTag name="h4" components={components}>{`Responses`}</MDXTag>
      <MDXTag name="h5" components={components}>{`▶ 200 - Response object with stats and success of instructions`}</MDXTag>
      <MDXTag name="h6" components={components}>{`application/json`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>success <strong>(required)</strong></td>
        <td>
          boolean
        </td>
        <td>All of the instructions succeeded or false if any failed or did not execute</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>beginTime <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Date and time of when set began to execute</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>completeTime <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Date and time of when set finished executing</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>instructions <strong>(required)</strong></td>
        <td>
          array(object)
        </td>
        <td></td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>instructions.success <strong>(required)</strong></td>
        <td>
          boolean
        </td>
        <td>Instruction succeeded or not</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>instructions.index <strong>(required)</strong></td>
        <td>
          integer
        </td>
        <td>Array index from requested Instructions array</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>instructions.returnValue</td>
        <td>
          -- 
        </td>
        <td><p>If instruction retrieved data, this will contain one of data of types listed below based on the output type specified by the instruction. For example a base64 string representing a buffer of 8-bit data, a utf-8 string, or a number.</p>
      </td>
        <td><em>Any</em></td>
      </tr>
        <tr>
          <td>instructions.returnValue.0</td>
          <td>
            string
          </td>
          <td></td>
          <td><em>Any</em></td>
        </tr>
        <tr>
          <td>instructions.returnValue.1</td>
          <td>
            number
          </td>
          <td></td>
          <td><em>Any</em></td>
        </tr>
      <tr>
        <td>instructions.error</td>
        <td>
          string
        </td>
        <td>Message possibly describing why instruction failed to execute</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`▶ default - Invalid packet, credentials, or some unexpected error`}</MDXTag>
      <MDXTag name="h6" components={components}>{`application/json`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>code <strong>(required)</strong></td>
        <td>
          integer
        </td>
        <td>Number to identify the error for tracking purposes</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>message <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Information describing what caused the error</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h3" components={components}>{`GET /api/v1/instr/stored`}</MDXTag>
      <a id="op-get-api-v1-instr-stored" />
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`Get names of all stored instruction sets`}</MDXTag>
      </MDXTag>
      <MDXTag name="h4" components={components}>{`Responses`}</MDXTag>
      <MDXTag name="h5" components={components}>{`▶ 200 - Successfully retreived a list of stored instruction set names`}</MDXTag>
      <MDXTag name="h6" components={components}>{`application/json`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>Response</td>
        <td>
          array(string)
        </td>
        <td>Array of names of each instruction set</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`▶ default - Invalid packet, credentials, or some unexpected error`}</MDXTag>
      <MDXTag name="h6" components={components}>{`application/json`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>code <strong>(required)</strong></td>
        <td>
          integer
        </td>
        <td>Number to identify the error for tracking purposes</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>message <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Information describing what caused the error</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h3" components={components}>{`GET /api/v1/instr/stored/{InstructionSetName}`}</MDXTag>
      <a id="op-get-api-v1-instr-stored-instructionsetname" />
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`Get instructions for given set name`}</MDXTag>
      </MDXTag>
      <MDXTag name="h4" components={components}>{`Path parameters`}</MDXTag>
      <MDXTag name="h5" components={components}>{`▷`}{` InstructionSetName`}</MDXTag>
      <MDXTag name="p" components={components}>{`Name of stored instruction set to retreive.`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>In</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>InstructionSetName  <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>path</td>
        <td><p>Name of stored instruction set to retreive.</p>
      </td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h4" components={components}>{`Responses`}</MDXTag>
      <MDXTag name="h5" components={components}>{`▶ 200 - A List of instructions that are stored for requested name`}</MDXTag>
      <MDXTag name="h6" components={components}>{`application/json`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>Response</td>
        <td>
          array
        </td>
        <td><p>Array of instructions.
      Each instruction is an object with a key, 'func'
      whose value is the name of the function. It also may contain keys
      'arg1', 'arg2', 'arg3' and associated values corresponding to arguments for the function.</p>
      <FuncLinks />
      </td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`▶ default - Invalid packet, credentials, or some unexpected error`}</MDXTag>
      <MDXTag name="h6" components={components}>{`application/json`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>code <strong>(required)</strong></td>
        <td>
          integer
        </td>
        <td>Number to identify the error for tracking purposes</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>message <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Information describing what caused the error</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h3" components={components}>{`POST /api/v1/instr/stored/{InstructionSetName}`}</MDXTag>
      <a id="op-post-api-v1-instr-stored-instructionsetname" />
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`Store instructions. This is useful so that you can later execute a sequence of instructions with a simple name.`}</MDXTag>
      </MDXTag>
      <MDXTag name="h4" components={components}>{`Path parameters`}</MDXTag>
      <MDXTag name="h5" components={components}>{`▷`}{` InstructionSetName`}</MDXTag>
      <MDXTag name="p" components={components}>{`Name of stored instruction set to store/save. When successfully added the special set with name 'initialize' will automatically be run every time the device starts up.`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>In</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>InstructionSetName  <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>path</td>
        <td><p>Name of stored instruction set to store/save. When successfully added the special set with name 'initialize' will automatically be run every time the device starts up.</p>
      </td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h4" components={components}>{`Request body`}</MDXTag>
      <MDXTag name="h6" components={components}>{`application/json`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>body</td>
        <td>
          array
        </td>
        <td><p>Array of instructions.
      Each instruction is an object with a key, 'func'
      whose value is the name of the function. It also may contain keys
      'arg1', 'arg2', 'arg3' and associated values corresponding to arguments for the function.</p>
      <FuncLinks />
      </td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`[
  {
    "func": "open",
    "arg1": 1,
    "arg2": "INPUT",
    "arg3": "PULL_UP"
  }
]
`}</MDXTag></MDXTag>
      <MDXTag name="h4" components={components}>{`Responses`}</MDXTag>
      <MDXTag name="h5" components={components}>{`▶ 200 - Object with success of storing the instructions set`}</MDXTag>
      <MDXTag name="h6" components={components}>{`application/json`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>success <strong>(required)</strong></td>
        <td>
          boolean
        </td>
        <td></td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>error</td>
        <td>
          -- 
        </td>
        <td>Common Error object among all requests</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>error.code <strong>(required)</strong></td>
        <td>
          integer
        </td>
        <td>Number to identify the error for tracking purposes</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>error.message <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Information describing what caused the error</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`▶ default - Invalid packet, credentials, or some unexpected error`}</MDXTag>
      <MDXTag name="h6" components={components}>{`application/json`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>code <strong>(required)</strong></td>
        <td>
          integer
        </td>
        <td>Number to identify the error for tracking purposes</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>message <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Information describing what caused the error</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h3" components={components}>{`POST /api/v1/instr/stored/{InstructionSetName}/exec`}</MDXTag>
      <a id="op-post-api-v1-instr-stored-instructionsetname-exec" />
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`Execute named instruction set`}</MDXTag>
      </MDXTag>
      <MDXTag name="h4" components={components}>{`Path parameters`}</MDXTag>
      <MDXTag name="h5" components={components}>{`▷`}{` InstructionSetName`}</MDXTag>
      <MDXTag name="p" components={components}>{`Name of stored instruction set to execute`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>In</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>InstructionSetName  <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>path</td>
        <td><p>Name of stored instruction set to execute</p>
      </td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h4" components={components}>{`Responses`}</MDXTag>
      <MDXTag name="h5" components={components}>{`▶ 200 - Response object with stats and success of each instruction`}</MDXTag>
      <MDXTag name="h6" components={components}>{`application/json`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>success <strong>(required)</strong></td>
        <td>
          boolean
        </td>
        <td>All of the instructions succeeded or false if any failed or did not execute</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>beginTime <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Date and time of when set began to execute</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>completeTime <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Date and time of when set finished executing</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>instructions <strong>(required)</strong></td>
        <td>
          array(object)
        </td>
        <td></td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>instructions.success <strong>(required)</strong></td>
        <td>
          boolean
        </td>
        <td>Instruction succeeded or not</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>instructions.index <strong>(required)</strong></td>
        <td>
          integer
        </td>
        <td>Array index from requested Instructions array</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>instructions.returnValue</td>
        <td>
          -- 
        </td>
        <td><p>If instruction retrieved data, this will contain one of data of types listed below based on the output type specified by the instruction. For example a base64 string representing a buffer of 8-bit data, a utf-8 string, or a number.</p>
      </td>
        <td><em>Any</em></td>
      </tr>
        <tr>
          <td>instructions.returnValue.0</td>
          <td>
            string
          </td>
          <td></td>
          <td><em>Any</em></td>
        </tr>
        <tr>
          <td>instructions.returnValue.1</td>
          <td>
            number
          </td>
          <td></td>
          <td><em>Any</em></td>
        </tr>
      <tr>
        <td>instructions.error</td>
        <td>
          string
        </td>
        <td>Message possibly describing why instruction failed to execute</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`▶ default - Invalid packet, credentials, or some unexpected error`}</MDXTag>
      <MDXTag name="h6" components={components}>{`application/json`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>code <strong>(required)</strong></td>
        <td>
          integer
        </td>
        <td>Number to identify the error for tracking purposes</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>message <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Information describing what caused the error</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h3" components={components}>{`GET /api/v1/instr/stored/{InstructionSetName}/log`}</MDXTag>
      <a id="op-get-api-v1-instr-stored-instructionsetname-log" />
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`Get latest response for instruction set with given name when it last executed`}</MDXTag>
      </MDXTag>
      <MDXTag name="h4" components={components}>{`Path parameters`}</MDXTag>
      <MDXTag name="h5" components={components}>{`▷`}{` InstructionSetName`}</MDXTag>
      <MDXTag name="p" components={components}>{`Name of stored instruction set's response log to retreive`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>In</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>InstructionSetName  <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>path</td>
        <td>Name of stored instruction set's response log to retreive</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h4" components={components}>{`Responses`}</MDXTag>
      <MDXTag name="h5" components={components}>{`▶ 200 - Object Response per each instruction`}</MDXTag>
      <MDXTag name="h6" components={components}>{`application/json`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>hasLog <strong>(required)</strong></td>
        <td>
          boolean
        </td>
        <td><p>Has this stored instruction set executed. If so, the log key will be present with the response of the last execution.</p>
      </td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>log</td>
        <td>
          object
        </td>
        <td><p>Object describing success and information of Instructions execution. It also contains an instructions key whose value is every instructions success status and returned data if applicapable. When a stored instruction set is executed the latest response object will be stored in it's corresponding log.</p>
      </td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>log.success <strong>(required)</strong></td>
        <td>
          boolean
        </td>
        <td>All of the instructions succeeded or false if any failed or did not execute</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>log.beginTime <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Date and time of when set began to execute</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>log.completeTime <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Date and time of when set finished executing</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>log.instructions <strong>(required)</strong></td>
        <td>
          array(object)
        </td>
        <td></td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>log.instructions.success <strong>(required)</strong></td>
        <td>
          boolean
        </td>
        <td>Instruction succeeded or not</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>log.instructions.index <strong>(required)</strong></td>
        <td>
          integer
        </td>
        <td>Array index from requested Instructions array</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>log.instructions.returnValue</td>
        <td>
          -- 
        </td>
        <td><p>If instruction retrieved data, this will contain one of data of types listed below based on the output type specified by the instruction. For example a base64 string representing a buffer of 8-bit data, a utf-8 string, or a number.</p>
      </td>
        <td><em>Any</em></td>
      </tr>
        <tr>
          <td>log.instructions.returnValue.0</td>
          <td>
            string
          </td>
          <td></td>
          <td><em>Any</em></td>
        </tr>
        <tr>
          <td>log.instructions.returnValue.1</td>
          <td>
            number
          </td>
          <td></td>
          <td><em>Any</em></td>
        </tr>
      <tr>
        <td>log.instructions.error</td>
        <td>
          string
        </td>
        <td>Message possibly describing why instruction failed to execute</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>error</td>
        <td>
          -- 
        </td>
        <td>Common Error object among all requests</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>error.code <strong>(required)</strong></td>
        <td>
          integer
        </td>
        <td>Number to identify the error for tracking purposes</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>error.message <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Information describing what caused the error</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`▶ default - Invalid packet, credentials, or some unexpected error`}</MDXTag>
      <MDXTag name="h6" components={components}>{`application/json`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>code <strong>(required)</strong></td>
        <td>
          integer
        </td>
        <td>Number to identify the error for tracking purposes</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>message <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Information describing what caused the error</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h2" components={components}>{`Schemas`}</MDXTag>
      <a id="schema-pi2c-general-settings" />
      <MDXTag name="h4" components={components}>{`PI2c General Settings`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>name</td>
        <td>
          string
        </td>
        <td>a user defined name for the device</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>password</td>
        <td>
          string
        </td>
        <td>a REST API password for Basic access authentication where the username is ignored</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>webURL</td>
        <td>
          string
        </td>
        <td>(advanced) a user defined URL serving custom HTML/JS to control the PI2c</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "name": "string",
  "password": "string",
  "webURL": "string"
}
`}</MDXTag></MDXTag>
      <a id="schema-pin-reset-type" />
      <MDXTag name="h4" components={components}>{`Pin reset type`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>PinResetType</td>
        <td>
          string
        </td>
        <td>How the pin is to be reset</td>
        <td><code>PIN_RESET</code>, <code>PIN_PRESERVE</code></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`"PIN_RESET"
`}</MDXTag></MDXTag>
      <a id="schema-poll-pin-direction" />
      <MDXTag name="h4" components={components}>{`Poll Pin direction`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>PollPinDirection</td>
        <td>
          string
        </td>
        <td>Check falling or rising edge or both when polling</td>
        <td><code>POLL_LOW</code>, <code>POLL_HIGH</code>, <code>POLL_BOTH</code></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`"POLL_LOW"
`}</MDXTag></MDXTag>
      <a id="schema-pin" />
      <MDXTag name="h4" components={components}>{`Pin`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>Pin</td>
        <td>
          number
        </td>
        <td>GPIO Pin number</td>
        <td><code>1</code>, <code>3</code>, <code>5</code>, <code>7</code>, <code>8</code>, <code>9</code>, <code>12</code>, <code>13</code>, <code>14</code>, <code>15</code>, <code>16</code>, <code>17</code>, <code>18</code></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`1
`}</MDXTag></MDXTag>
      <a id="schema-pin-mode" />
      <MDXTag name="h4" components={components}>{`Pin Mode`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>PinMode</td>
        <td>
          string
        </td>
        <td>Set whether GPIO Pin is input, output, or pwm if capable</td>
        <td><code>INPUT</code>, <code>OUTPUT</code>, <code>PWM</code></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`"INPUT"
`}</MDXTag></MDXTag>
      <a id="schema-pullup" />
      <MDXTag name="h4" components={components}>{`Pullup`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>PinInputModePullup</td>
        <td>
          string
        </td>
        <td>Pin Pullup in input mode</td>
        <td><code>PULL_UP</code>, <code>PULL_DOWN</code>, <code>PULL_OFF</code></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`"PULL_UP"
`}</MDXTag></MDXTag>
      <a id="schema-pin-value" />
      <MDXTag name="h4" components={components}>{`Pin Value`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>PinOutputModeValue</td>
        <td>
          string
        </td>
        <td>Pin string value in output mode</td>
        <td><code>HIGH</code>, <code>LOW</code></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`"HIGH"
`}</MDXTag></MDXTag>
      <a id="schema-pin-numerical-value" />
      <MDXTag name="h4" components={components}>{`Pin Numerical Value`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>PinOutputNumModeValue</td>
        <td>
          number
        </td>
        <td>Pin numerical value in output mode</td>
        <td><code>1</code>, <code>0</code></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`1
`}</MDXTag></MDXTag>
      <a id="schema-led" />
      <MDXTag name="h4" components={components}>{`led`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>func <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Instruction function name</td>
        <td><code>led</code></td>
      </tr>
      <tr>
        <td>arg1 <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td><p>Which led (red or green). Note on startup when device is ready, red led will turn on for a second.</p>
      </td>
        <td><code>red</code>, <code>green</code></td>
      </tr>
      <tr>
        <td>arg2 <strong>(required)</strong></td>
        <td>
          -- 
        </td>
        <td></td>
        <td><em>Any</em></td>
      </tr>
        <tr>
          <td>arg2.0</td>
          <td>
            string
          </td>
          <td></td>
          <td><code>HIGH</code>, <code>LOW</code></td>
        </tr>
        <tr>
          <td>arg2.1</td>
          <td>
            number
          </td>
          <td></td>
          <td><code>1</code>, <code>0</code></td>
        </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "func": "led",
  "arg1": "red",
  "arg2": "HIGH"
}
`}</MDXTag></MDXTag>
      <a id="schema-open" />
      <MDXTag name="h4" components={components}>{`open`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>func <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Instruction function name</td>
        <td><code>open</code></td>
      </tr>
      <tr>
        <td>arg1 <strong>(required)</strong></td>
        <td>
          number
        </td>
        <td>GPIO Pin number</td>
        <td><code>1</code>, <code>3</code>, <code>5</code>, <code>7</code>, <code>8</code>, <code>9</code>, <code>12</code>, <code>13</code>, <code>14</code>, <code>15</code>, <code>16</code>, <code>17</code>, <code>18</code></td>
      </tr>
      <tr>
        <td>arg2 <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Set whether GPIO Pin is input, output, or pwm if capable</td>
        <td><code>INPUT</code>, <code>OUTPUT</code>, <code>PWM</code></td>
      </tr>
      <tr>
        <td>arg3</td>
        <td>
          -- 
        </td>
        <td></td>
        <td><em>Any</em></td>
      </tr>
        <tr>
          <td>arg3.0</td>
          <td>
            string
          </td>
          <td></td>
          <td><code>PULL_UP</code>, <code>PULL_DOWN</code>, <code>PULL_OFF</code></td>
        </tr>
        <tr>
          <td>arg3.1</td>
          <td>
            string
          </td>
          <td></td>
          <td><code>HIGH</code>, <code>LOW</code></td>
        </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "func": "open",
  "arg1": 1,
  "arg2": "INPUT",
  "arg3": "PULL_UP"
}
`}</MDXTag></MDXTag>
      <a id="schema-stored" />
      <MDXTag name="h4" components={components}>{`stored`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>func <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Instruction function name</td>
        <td><code>stored</code></td>
      </tr>
      <tr>
        <td>arg1 <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Name of stored instruction set to execute</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "func": "stored",
  "arg1": "string"
}
`}</MDXTag></MDXTag>
      <a id="schema-mode" />
      <MDXTag name="h4" components={components}>{`mode`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>func <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Instruction function name</td>
        <td><code>mode</code></td>
      </tr>
      <tr>
        <td>arg1 <strong>(required)</strong></td>
        <td>
          number
        </td>
        <td>GPIO Pin number</td>
        <td><code>1</code>, <code>3</code>, <code>5</code>, <code>7</code>, <code>8</code>, <code>9</code>, <code>12</code>, <code>13</code>, <code>14</code>, <code>15</code>, <code>16</code>, <code>17</code>, <code>18</code></td>
      </tr>
      <tr>
        <td>arg2 <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Set whether GPIO Pin is input, output, or pwm if capable</td>
        <td><code>INPUT</code>, <code>OUTPUT</code>, <code>PWM</code></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "func": "mode",
  "arg1": 1,
  "arg2": "INPUT"
}
`}</MDXTag></MDXTag>
      <a id="schema-read" />
      <MDXTag name="h4" components={components}>{`read`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>func <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Instruction function name</td>
        <td><code>read</code></td>
      </tr>
      <tr>
        <td>arg1 <strong>(required)</strong></td>
        <td>
          number
        </td>
        <td>GPIO Pin number</td>
        <td><code>1</code>, <code>3</code>, <code>5</code>, <code>7</code>, <code>8</code>, <code>9</code>, <code>12</code>, <code>13</code>, <code>14</code>, <code>15</code>, <code>16</code>, <code>17</code>, <code>18</code></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "func": "read",
  "arg1": 1
}
`}</MDXTag></MDXTag>
      <a id="schema-readbuf" />
      <MDXTag name="h4" components={components}>{`readBuf`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>func <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Instruction function name</td>
        <td><code>readBuf</code></td>
      </tr>
      <tr>
        <td>arg1 <strong>(required)</strong></td>
        <td>
          number
        </td>
        <td>GPIO Pin number</td>
        <td><code>1</code>, <code>3</code>, <code>5</code>, <code>7</code>, <code>8</code>, <code>9</code>, <code>12</code>, <code>13</code>, <code>14</code>, <code>15</code>, <code>16</code>, <code>17</code>, <code>18</code></td>
      </tr>
      <tr>
        <td>arg2 <strong>(required)</strong></td>
        <td>
          integer
        </td>
        <td>Number of bits to read</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "func": "readBuf",
  "arg1": 1,
  "arg2": 1
}
`}</MDXTag></MDXTag>
      <a id="schema-write" />
      <MDXTag name="h4" components={components}>{`write`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>func <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Instruction function name</td>
        <td><code>write</code></td>
      </tr>
      <tr>
        <td>arg1 <strong>(required)</strong></td>
        <td>
          number
        </td>
        <td>GPIO Pin number</td>
        <td><code>1</code>, <code>3</code>, <code>5</code>, <code>7</code>, <code>8</code>, <code>9</code>, <code>12</code>, <code>13</code>, <code>14</code>, <code>15</code>, <code>16</code>, <code>17</code>, <code>18</code></td>
      </tr>
      <tr>
        <td>arg2 <strong>(required)</strong></td>
        <td>
          -- 
        </td>
        <td>set the output of the pin using string or number types</td>
        <td><em>Any</em></td>
      </tr>
        <tr>
          <td>arg2.0</td>
          <td>
            string
          </td>
          <td></td>
          <td><code>HIGH</code>, <code>LOW</code></td>
        </tr>
        <tr>
          <td>arg2.1</td>
          <td>
            number
          </td>
          <td></td>
          <td><code>1</code>, <code>0</code></td>
        </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "func": "write",
  "arg1": 1,
  "arg2": "HIGH"
}
`}</MDXTag></MDXTag>
      <a id="schema-writebuf" />
      <MDXTag name="h4" components={components}>{`writeBuf`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>func <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Instruction function name</td>
        <td><code>writeBuf</code></td>
      </tr>
      <tr>
        <td>arg1 <strong>(required)</strong></td>
        <td>
          number
        </td>
        <td>GPIO Pin number</td>
        <td><code>1</code>, <code>3</code>, <code>5</code>, <code>7</code>, <code>8</code>, <code>9</code>, <code>12</code>, <code>13</code>, <code>14</code>, <code>15</code>, <code>16</code>, <code>17</code>, <code>18</code></td>
      </tr>
      <tr>
        <td>arg2 <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>base64 encoded data to be written. Each byte should be 1 or 0 to represent a bit</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "func": "writeBuf",
  "arg1": 1,
  "arg2": "string"
}
`}</MDXTag></MDXTag>
      <a id="schema-pud" />
      <MDXTag name="h4" components={components}>{`pud`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>func <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Instruction function name</td>
        <td><code>pud</code></td>
      </tr>
      <tr>
        <td>arg1 <strong>(required)</strong></td>
        <td>
          number
        </td>
        <td>GPIO Pin number</td>
        <td><code>1</code>, <code>3</code>, <code>5</code>, <code>7</code>, <code>8</code>, <code>9</code>, <code>12</code>, <code>13</code>, <code>14</code>, <code>15</code>, <code>16</code>, <code>17</code>, <code>18</code></td>
      </tr>
      <tr>
        <td>arg2 <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Pin Pullup in input mode</td>
        <td><code>PULL_UP</code>, <code>PULL_DOWN</code>, <code>PULL_OFF</code></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "func": "pud",
  "arg1": 1,
  "arg2": "PULL_UP"
}
`}</MDXTag></MDXTag>
      <a id="schema-pwmsetclockdivider" />
      <MDXTag name="h4" components={components}>{`pwmSetClockDivider`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>func <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Instruction function name</td>
        <td><code>pwmSetClockDivider</code></td>
      </tr>
      <tr>
        <td>arg1 <strong>(required)</strong></td>
        <td>
          number
        </td>
        <td><p>This is a power-of-two divisor of the base 19.2MHz rate, with a maximum value of 4096 (4.6875kHz).</p>
      </td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "func": "pwmSetClockDivider",
  "arg1": 1
}
`}</MDXTag></MDXTag>
      <a id="schema-pwmsetrange" />
      <MDXTag name="h4" components={components}>{`pwmSetRange`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>func <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Instruction function name</td>
        <td><code>pwmSetRange</code></td>
      </tr>
      <tr>
        <td>arg1 <strong>(required)</strong></td>
        <td>
          number
        </td>
        <td></td>
        <td><code>16</code>, <code>18</code></td>
      </tr>
      <tr>
        <td>arg2 <strong>(required)</strong></td>
        <td>
          number
        </td>
        <td>set range</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "func": "pwmSetRange",
  "arg1": 16,
  "arg2": 1
}
`}</MDXTag></MDXTag>
      <a id="schema-pwmsetdata" />
      <MDXTag name="h4" components={components}>{`pwmSetData`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>func <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Instruction function name</td>
        <td><code>pwmSetData</code></td>
      </tr>
      <tr>
        <td>arg1 <strong>(required)</strong></td>
        <td>
          number
        </td>
        <td></td>
        <td><code>16</code>, <code>18</code></td>
      </tr>
      <tr>
        <td>arg2 <strong>(required)</strong></td>
        <td>
          number
        </td>
        <td>set data</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "func": "pwmSetData",
  "arg1": 16,
  "arg2": 1
}
`}</MDXTag></MDXTag>
      <a id="schema-i2cbegin" />
      <MDXTag name="h4" components={components}>{`i2cBegin`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>func <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Assign GPIO pins 1 and 3 to use i2c</td>
        <td><code>i2cBegin</code></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "func": "i2cBegin"
}
`}</MDXTag></MDXTag>
      <a id="schema-i2csetslaveaddress" />
      <MDXTag name="h4" components={components}>{`i2cSetSlaveAddress`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>func <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Instruction function name</td>
        <td><code>i2cSetSlaveAddress</code></td>
      </tr>
      <tr>
        <td>arg1 <strong>(required)</strong></td>
        <td>
          number
        </td>
        <td>Configure the slave address. This is between 0 - 0x7f</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "func": "i2cSetSlaveAddress",
  "arg1": 0
}
`}</MDXTag></MDXTag>
      <a id="schema-i2csetbaudrate" />
      <MDXTag name="h4" components={components}>{`i2cSetBaudRate`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>func <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Instruction function name</td>
        <td><code>i2cSetBaudRate</code></td>
      </tr>
      <tr>
        <td>arg1 <strong>(required)</strong></td>
        <td>
          number
        </td>
        <td>The i2c baudrate</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "func": "i2cSetBaudRate",
  "arg1": 1000
}
`}</MDXTag></MDXTag>
      <a id="schema-i2cread" />
      <MDXTag name="h4" components={components}>{`i2cRead`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>func <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Instruction function name</td>
        <td><code>i2cRead</code></td>
      </tr>
      <tr>
        <td>arg1 <strong>(required)</strong></td>
        <td>
          number
        </td>
        <td>Number of bytes to read</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "func": "i2cRead",
  "arg1": 1
}
`}</MDXTag></MDXTag>
      <a id="schema-i2cwrite" />
      <MDXTag name="h4" components={components}>{`i2cWrite`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>func <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Instruction function name</td>
        <td><code>i2cWrite</code></td>
      </tr>
      <tr>
        <td>arg1 <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>base64 representation of bytes to write</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "func": "i2cWrite",
  "arg1": "string"
}
`}</MDXTag></MDXTag>
      <a id="schema-i2cupdatereg" />
      <MDXTag name="h4" components={components}>{`i2cUpdateReg`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>func <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Instruction function name</td>
        <td><code>i2cUpdateReg</code></td>
      </tr>
      <tr>
        <td>arg1 <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>n-byte register value</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>arg2 <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Mask - to 4 byte mask value base64. Num bytes should be equal to value</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>arg3 <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Value - up to 4 byte data value base64. Num bytes should be equal to mask</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "func": "i2cUpdateReg",
  "arg1": "stri",
  "arg2": "string",
  "arg3": "string"
}
`}</MDXTag></MDXTag>
      <a id="schema-i2cend" />
      <MDXTag name="h4" components={components}>{`i2cEnd`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>func <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Instruction function name</td>
        <td><code>i2cEnd</code></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "func": "i2cEnd"
}
`}</MDXTag></MDXTag>
      <a id="schema-spibegin" />
      <MDXTag name="h4" components={components}>{`spiBegin`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>func <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Instruction function name</td>
        <td><code>spiBegin</code></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "func": "spiBegin"
}
`}</MDXTag></MDXTag>
      <a id="schema-spisetcspolarity" />
      <MDXTag name="h4" components={components}>{`spiSetCSPolarity`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>func <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Instruction function name</td>
        <td><code>spiSetCSPolarity</code></td>
      </tr>
      <tr>
        <td>arg1 <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td></td>
        <td><code>HIGH</code></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "func": "spiSetCSPolarity",
  "arg1": "HIGH"
}
`}</MDXTag></MDXTag>
      <a id="schema-spisetclockdivider" />
      <MDXTag name="h4" components={components}>{`spiSetClockDivider`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>func <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Instruction function name</td>
        <td><code>spiSetClockDivider</code></td>
      </tr>
      <tr>
        <td>arg1 <strong>(required)</strong></td>
        <td>
          number
        </td>
        <td>Even divisor of the base 250MHz rate ranging between 0 and 65536. If div is 128 set SPI speed to 1.95MHz</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "func": "spiSetClockDivider",
  "arg1": 128
}
`}</MDXTag></MDXTag>
      <a id="schema-spisetdatamode" />
      <MDXTag name="h4" components={components}>{`spiSetDataMode`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>func <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Instruction function name</td>
        <td><code>spiSetDataMode</code></td>
      </tr>
      <tr>
        <td>arg1 <strong>(required)</strong></td>
        <td>
          number
        </td>
        <td>0=CPOL(0)CPHA(0),1=CPOL(0)CPHA(1),2=CPOL(1)CPHA(0),3=CPOL(1)CPHA(1)</td>
        <td><code>0</code>, <code>1</code>, <code>3</code></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "func": "spiSetDataMode",
  "arg1": 0
}
`}</MDXTag></MDXTag>
      <a id="schema-spitransfer" />
      <MDXTag name="h4" components={components}>{`spiTransfer`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>func <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Instruction function name</td>
        <td><code>spiTransfer</code></td>
      </tr>
      <tr>
        <td>arg1 <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>base64 representation of bytes to write</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "func": "spiTransfer",
  "arg1": "string"
}
`}</MDXTag></MDXTag>
      <a id="schema-spiwrite" />
      <MDXTag name="h4" components={components}>{`spiWrite`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>func <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Instruction function name</td>
        <td><code>spiWrite</code></td>
      </tr>
      <tr>
        <td>arg1 <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>base64 representation of bytes to write</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "func": "spiWrite",
  "arg1": "string"
}
`}</MDXTag></MDXTag>
      <a id="schema-spiend" />
      <MDXTag name="h4" components={components}>{`spiEnd`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>func <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Instruction function name</td>
        <td><code>spiEnd</code></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "func": "spiEnd"
}
`}</MDXTag></MDXTag>
      <a id="schema-sleep" />
      <MDXTag name="h4" components={components}>{`sleep`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>func <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Instruction function name</td>
        <td><code>sleep</code></td>
      </tr>
      <tr>
        <td>arg1 <strong>(required)</strong></td>
        <td>
          number
        </td>
        <td>number of seconds to sleep</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "func": "sleep",
  "arg1": 1
}
`}</MDXTag></MDXTag>
      <a id="schema-msleep" />
      <MDXTag name="h4" components={components}>{`msleep`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>func <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Instruction function name</td>
        <td><code>msleep</code></td>
      </tr>
      <tr>
        <td>arg1 <strong>(required)</strong></td>
        <td>
          number
        </td>
        <td>Number of milliseconds to sleep</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "func": "msleep",
  "arg1": 1
}
`}</MDXTag></MDXTag>
      <a id="schema-usleep" />
      <MDXTag name="h4" components={components}>{`usleep`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>func <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Instruction function name</td>
        <td><code>usleep</code></td>
      </tr>
      <tr>
        <td>arg1 <strong>(required)</strong></td>
        <td>
          number
        </td>
        <td>Number of microseconds to sleep</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "func": "usleep",
  "arg1": 100
}
`}</MDXTag></MDXTag>
      <a id="schema-instructions" />
      <MDXTag name="h4" components={components}>{`Instructions`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>Instructions</td>
        <td>
          array
        </td>
        <td><p>Array of instructions.
      Each instruction is an object with a key, 'func'
      whose value is the name of the function. It also may contain keys
      'arg1', 'arg2', 'arg3' and associated values corresponding to arguments for the function.</p>
      <FuncLinks />
      </td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`[
  {
    "func": "open",
    "arg1": 1,
    "arg2": "INPUT",
    "arg3": "PULL_UP"
  }
]
`}</MDXTag></MDXTag>
      <a id="schema-instruction-response" />
      <MDXTag name="h4" components={components}>{`Instruction Response`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>success <strong>(required)</strong></td>
        <td>
          boolean
        </td>
        <td>Instruction succeeded or not</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>index <strong>(required)</strong></td>
        <td>
          integer
        </td>
        <td>Array index from requested Instructions array</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>returnValue</td>
        <td>
          -- 
        </td>
        <td><p>If instruction retrieved data, this will contain one of data of types listed below based on the output type specified by the instruction. For example a base64 string representing a buffer of 8-bit data, a utf-8 string, or a number.</p>
      </td>
        <td><em>Any</em></td>
      </tr>
        <tr>
          <td>returnValue.0</td>
          <td>
            string
          </td>
          <td></td>
          <td><em>Any</em></td>
        </tr>
        <tr>
          <td>returnValue.1</td>
          <td>
            number
          </td>
          <td></td>
          <td><em>Any</em></td>
        </tr>
      <tr>
        <td>error</td>
        <td>
          string
        </td>
        <td>Message possibly describing why instruction failed to execute</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "success": true,
  "index": 0,
  "returnValue": "AAE="
}
`}</MDXTag></MDXTag>
      <a id="schema-instructions-response" />
      <MDXTag name="h4" components={components}>{`Instructions Response`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>success <strong>(required)</strong></td>
        <td>
          boolean
        </td>
        <td>All of the instructions succeeded or false if any failed or did not execute</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>beginTime <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Date and time of when set began to execute</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>completeTime <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Date and time of when set finished executing</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>instructions <strong>(required)</strong></td>
        <td>
          array(object)
        </td>
        <td></td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>instructions.success <strong>(required)</strong></td>
        <td>
          boolean
        </td>
        <td>Instruction succeeded or not</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>instructions.index <strong>(required)</strong></td>
        <td>
          integer
        </td>
        <td>Array index from requested Instructions array</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>instructions.returnValue</td>
        <td>
          -- 
        </td>
        <td><p>If instruction retrieved data, this will contain one of data of types listed below based on the output type specified by the instruction. For example a base64 string representing a buffer of 8-bit data, a utf-8 string, or a number.</p>
      </td>
        <td><em>Any</em></td>
      </tr>
        <tr>
          <td>instructions.returnValue.0</td>
          <td>
            string
          </td>
          <td></td>
          <td><em>Any</em></td>
        </tr>
        <tr>
          <td>instructions.returnValue.1</td>
          <td>
            number
          </td>
          <td></td>
          <td><em>Any</em></td>
        </tr>
      <tr>
        <td>instructions.error</td>
        <td>
          string
        </td>
        <td>Message possibly describing why instruction failed to execute</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "success": true,
  "beginTime": "2019-05-25T20:40:57Z",
  "completeTime": "2019-05-25T20:40:57Z",
  "instructions": [
    {
      "success": true,
      "index": 0,
      "returnValue": "AAE="
    }
  ]
}
`}</MDXTag></MDXTag>
      <a id="schema-error" />
      <MDXTag name="h4" components={components}>{`Error`}</MDXTag>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
      <th>Accepted values</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>code <strong>(required)</strong></td>
        <td>
          integer
        </td>
        <td>Number to identify the error for tracking purposes</td>
        <td><em>Any</em></td>
      </tr>
      <tr>
        <td>message <strong>(required)</strong></td>
        <td>
          string
        </td>
        <td>Information describing what caused the error</td>
        <td><em>Any</em></td>
      </tr>
  </tbody>
      </table>
      <MDXTag name="h5" components={components}>{`Example `}<MDXTag name="em" components={components} parentName="h5">{`(generated)`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-json"
        }}>{`{
  "code": 0,
  "message": "string"
}
`}</MDXTag></MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      